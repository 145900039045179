/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
}

.label {
  @apply block mb-2 font-bold w-24 items-start text-sm subpixel-antialiased tracking-wider whitespace-nowrap;
}

.btn {
  @apply normal-case;
}

// .btn-primary {
//   @apply w-full bg-blue-600 text-white rounded-md p-2 m-6  subpixel-antialiased hover:bg-blue-400 cursor-pointer tracking-wider;
// }

// .btn-default {
//   @apply w-full border-s-slate-400 rounded-md p-2 m-6  subpixel-antialiased bg-slate-100 hover:bg-slate-200 hover:text-gray-600 cursor-pointer tracking-wider;
// }

.btn-default-text {
  @apply border-slate-400  subpixel-antialiased hover:bg-slate-100 hover:text-gray-500 cursor-pointer tracking-wider;
}

.btn-disabled {
  @apply subpixel-antialiased bg-slate-200 text-slate-400  cursor-not-allowed tracking-wider;
}

.btn-icon {
  @apply rounded-full cursor-pointer border-0 h-8 w-8 p-1  m-2 bg-gray-500 inline-block bg-opacity-5;
}

.inputForm {
  @apply w-full border-s-slate-400 bg-slate-100 h-10 p-4 rounded-md hover:outline-1 hover:outline-slate-200 focus:outline-slate-400 active:outline-slate-400;
}

.numberForm {
  @apply w-full border-s-slate-400 bg-slate-100 h-10 rounded-md hover:outline-1 hover:outline-slate-200 focus:outline-slate-400 active:outline-slate-400 p-2;
}

.numberField {
  @apply border-none bg-transparent w-full;
}

.inputIconForm {
  @apply w-full border-s-slate-400 bg-slate-100 h-10 pl-10 p-4 rounded-md hover:outline-1 hover:outline-slate-200 focus:outline-slate-400 active:outline-slate-400;
}

.textareaForm {
  @apply w-full border-s-slate-400 bg-slate-100 h-24 p-4 rounded-md hover:outline-1 hover:outline-slate-200 focus:outline-slate-400 active:outline-slate-400;
}

.inputFormWarning {
  @apply w-full bg-slate-100 h-10 p-4 rounded-md border-dotted border-2 border-red-400;
}

.inputFormSuccess {
  @apply w-full bg-slate-100 h-10 p-4 rounded-md border-solid border-2 border-green-400;
}

.inputFormAlert {
  @apply text-sm text-red-600;
}

.iconSvg {
  @apply w-7 h-7 stroke-1 group-hover/button:stroke-blue-600;
}
.iconSvg-delete {
  @apply w-7 h-7 stroke-1 group-hover/button:stroke-red-600;
}

.divider {
  @apply w-full border-b-2 border-s-slate-200 mt-4 mb-4;
}

.bouncing {
  @apply transition ease-in-out delay-75 hover:-translate-y-1 hover:scale-100 duration-300;
}

.btn-hover {
  @apply hover:shadow-lg hover:shadow-blue-500/40;
}

.select-menu {
  @apply w-full pt-1 bg-slate-100 rounded-md h-10 hover:outline-1 hover:outline-slate-200 focus:outline-slate-400 active:outline-slate-400;
}

.tagSelection {
  @apply w-full flex items-center justify-start bg-blue-950 p-6 h-4 rounded-md;
}

.tagSelectionText {
  @apply tracking-wider text-white;
}

.viewport {
  @apply w-full overflow-x-hidden;
}

.required:after {
  content: " *";
  color: rgb(220, 38, 38);
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  overflow: hidden !important;
}

$darkbackbround: #111b26;
$pinkColor: #c62e65;
$greenColor: #1ea896;
$whiteColor: #ffffff;
$backColor: #191923;
$yellowColor: #f3b61f;
$third: #b5eaff;

body {
  margin: 0 !important;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.ant-modal-content {
  border-radius: 12px;
}

.btn-primary-action {
  color: #fff;
  background-color: #f14a1c;
}

.matAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  padding-left: 3px;
  background-color: #bfbfbf;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
}

.emptyCard {
  margin-top: 10%;
  height: 25%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  justify-items: center;
  width: 100%;
}

.iconEmpty {
  font-size: 40px;
  color: #bfbfbf !important;
  flex-flow: row;
  justify-content: center;
  justify-items: center;
}
.contentEmpty {
  color: #bfbfbf !important;
}

.tabButton {
  background-color: #062949;
  color: white;
}

.iconDelete {
  :hover {
    color: red;
  }
}

.primaryRed {
  color: #ff0000;
}

.primaryBlue {
  color: #062949;
}

.primaryBlueNavy {
  color: #2d7dd2;
}

.primaryBlack {
  color: #191923;
}

.primaryWhite {
  color: #fbfef9;
}

.primaryGrey {
  color: #84828f;
}

h2,
h2,
h3,
h4,
h5,
h6,
p,
strong {
  color: #062949;
}

.delete {
  color: #ff0000 !important;
}

.ant-tabs-ink-bar {
  background-color: transparent;
  color: none;
}

.ant-menu-item-selected {
  background-color: #fff !important;
  // color: #062949 !important;
  mat-icon,
  span {
    color: #062949 !important;
  }
}

.ant-tooltip-inner {
  color: white;
}

nz-select {
  width: 100%;
}

.fullscreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.primary-action {
  color: #91caff;
  border: none;
  p {
    color: #1677ff;
  }
}

.delete-action {
  border: none;

  p,
  i,
  span,
  mat-icon {
    color: grey;
  }
  :hover {
    color: #ff4d4f;
    border: none;

    p,
    i,
    span,
    mat-icon {
      color: #ffccc7;
    }
  }
}
